* {
  border: 0;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 1em;
  margin: 0;
  outline: 0;
  padding: 0;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  text-transform: none; // mac ff fix
  -webkit-font-smoothing: antialiased; // safari mac nicer
  -moz-osx-font-smoothing: grayscale; // fix fatty ff on mac

  &:focus-visible{
    outline: 3px solid $TNO-blue !important;
  }
}

html {
  scroll-behavior: smooth;
  background: $site-background;
  height: rem(342);
  &.is-desktop {
    overflow-x: visible;
    overflow-y: visible;
    height: rem(670);
  }
}

body {
  font-weight: $body-font-weight;
  font-size: $body-font-size;
  font-family: $body-font-family;
  color: $white;
  line-height: $body-line-height;
  margin: 0 auto;
  height: rem(342);

  .is-desktop & {
    height: rem(670);
  }

  .intro {
    width: 70vw;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
      width: 60vw;
    }
    @include media-breakpoint-up(md) {
      width: 50vw;
    }
    @include media-breakpoint-up(lg) {
      width: 40vw;
    }
    @include media-breakpoint-up(xl) {
      width: 30vw;
    }


    h1 {
      color:$black;
      padding-top:rem(40);
    }
    p {
      padding-top:rem(16);
    }
  }
}

ol {
  margin-left: 0;
}
ul {
  list-style: none;
  li {
    padding-left: 0;
    position: relative;
  }
}
table {
  border-collapse: collapse;
}
small{
  font-size: 80%;
}

button {
  background: none;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

p {
  color: $TNO-ink;
  font-weight: 400;
}

details summary::-webkit-details-marker {
  visibility: hidden;
}

span {
  font-weight: 500;
}