@use "sass:math";

%grid-column {
  position: relative;
  width: 100%;
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
}

@each $breakpoint in map-keys($grid-breakpoints) {

  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @for $i from 1 through $grid-columns {
    .col#{$infix}-#{$i} {
      @extend %grid-column;
    }
  }
  .col#{$infix},
  .col#{$infix}-auto {
    @extend %grid-column;
  }

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .col#{$infix} {
      flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      min-width: 0; // See https://github.com/twbs/bootstrap/issues/25410
    }

    @if $grid-columns > 0 {
      @for $i from 1 through $grid-columns {
        .col#{$infix}-#{$i} {

          flex: 0 0 auto;
          width: calc(($i / $grid-columns) * 100%);
        }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($grid-columns - 1) {
        @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
          .offset#{$infix}-#{$i} {
            $num: math.div($i, $grid-columns);
            margin-left: if($num == 0, 0, percentage($num));
          }
        }
      }
    }
  }
}