.popup {
  visibility: hidden;
  position: absolute;
  width: calc(100% - 48px);
  height: fit-content;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #E7ECF8;
  z-index: 2;
  padding: rem(14) rem(44) rem(14) rem(18);
  box-shadow: inset 0 rem(-4) rem(8) rgba(0, 0, 0, 0.05);

  .is-desktop & {
    width: rem(373);
    padding: rem(24) rem(24) rem(72) rem(24);
    box-shadow: 0 rem(4) rem(4) rgba(0, 0, 0, 0.25);
    top: rem(40);
    left: unset;
    right: rem(40);
    transform: unset;
  }

  h1 {
    color: $TNO-blue;
    margin-bottom: rem(8);
    font-weight: 700;
    font-size: rem(18);
    line-height: rem(28);

    .is-desktop & {
      margin-bottom: rem(16);
      font-size: rem(24);
      line-height: rem(32);
    }
  }

  p {
    font-weight: 400;
    font-size: rem(16);
    line-height: rem(22);

    .is-desktop & {
      font-size: rem(20);
      line-height: rem(28);
    }
  }

  &__back-btn {
    position: absolute;
    right: rem(9);
    bottom: 4%;
    .is-desktop & {
      right: rem(24);
      bottom: rem(24);
    }
  }
}

.popup.show {
  visibility: visible;
  left: 50%;
}