.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
  width: 100%;
  max-width: $container-max-width;

  @include media-breakpoint-up(md) {
    max-width: 85vw;
  }

  @include media-breakpoint-up(lg) {
    max-width: $container-max-width;
    padding-left: 0;
    padding-right: 0;
  }

  &.no-padding {
    padding-left: 0;
    padding-right: 0;

    > .row {
      margin-left: 0;
      margin-right: 0;

      > [class*='col-'] {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &--no-max {
    max-width: unset;
  }
  &.no-padding-mobile{
    padding-left: 0;
    padding-right: 0;

    > .row{
      margin-left: 0;
      margin-right: 0;

      >[class*='col-']{
        padding-left: 0;
        padding-right: 0;
      }
    }

    @include media-breakpoint-up(md){
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;

      > .row{
        margin-left: calc($grid-gutter-width / -2);
        margin-right: calc($grid-gutter-width / -2);

        >[class*='col-']{
          padding-left: calc($grid-gutter-width / 2);
          padding-right: calc($grid-gutter-width / 2);
        }
      }
    }
  }
}