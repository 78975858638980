%vector-lines {
  content: '';
  position: absolute;
  background-color: $TNO-action-orange;
}

%vector-dot {
  border-radius: 100%;
  width: rem(4);
  height: rem(4);
}