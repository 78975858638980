@use "sass:math";

//*** Global styles ***//
$path: '../files/';
$images: '../files/images/';

// # Colors
$white: #fff;
$black: #000;
$fallback-color: $black;
$fallback-color-light: rgba($fallback-color, 0.24);
$site-background: $white;

// Primary colors
$TNO-blue: #123EB7;
$TNO-ink: #00030A;

// Secondary colors
$TNO-blue-dark: #002484;
$TNO-action-blue: #3369FF;
$TNO-action-orange: #F57118;

// Neutrals colors
$TNO-gray: #F0F3FA;

// # Fonts
$body-font-family: 'Object Sans', sans-serif;
$body-font-weight: 500;
$body-font-size: rem(16);
$body-line-height: rem(24);
$body-font-color: $black;

// General heading styles

// # Spacing
$spacer: rem(32)!default;
$spacers: (
  0: 0,
  4: ($spacer * .25),
  8: ($spacer * .5),
  16: $spacer,
  24: ($spacer * 1.5),
  32: ($spacer * 2),
  40: ($spacer * 2.5),
  48: ($spacer * 3),
  56: ($spacer * 3.5),
  64: ($spacer * 4),
  72: ($spacer * 4.5),
  80: ($spacer * 5),
  88: ($spacer * 5.5),
  96: ($spacer * 6),
  104: ($spacer * 6.5),
  112: ($spacer * 7),
  120: ($spacer * 7.5),
  128: ($spacer * 8),
  136: ($spacer * 8.5),
  144: ($spacer * 9),
);

// # Breakpoints

$grid-breakpoints: (
    xs: 768px,
    sm: 1024px,
    md: 1280px,
    lg: 1366px,
    xl: 1920px
) !default;

// # Containers
$container-max-width: rem(1130);

// # Columns
$grid-columns: 12;
$grid-gutter-width: rem(16);
$gutters: $spacers !default;

// Taken from https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss.
$aspect-ratios: (
  "1x1": 100%,
  "4x3":  math.div(3,4),
  "16x9":  math.div(9,16),
  "16x10":  math.div(10,16),
);