.hidden {
  display: none;
}

.hidden-visibility {
  opacity: 0;
  pointer-events: none;
}

.icon {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: rem(39);
  width: rem(38);

  .is-desktop & {
    height: rem(49);
    width: rem(48);
  }

  &--back-btn {
    background-image: url("/assets/images/icon-back-btn.svg");
    margin-right: rem(9);
  }

  &--back-btn-popup {
    background-image: url("/assets/images/icon-back-btn-popup.svg");
  }
}

.telescope-inside {
  .img__responsive.img--contain img {
    object-position: center;
  }

  .telescope__responsive-container {
    width: rem(343);
    height: 100%;
    margin: 0 auto;
    position: absolute;
    inset: 0;

    .is-desktop & {
      width: rem(830);
    }
  }

  .telescope__description--title {
    margin: 0;

    h1 {
      .is-desktop & {
        font-size: rem(16);
      }
    }
  }

  .telescope__img {
    .is-desktop & {
      top: 53.7%;
    }

    figure {
      width: rem(323);

      .is-desktop & {
        width: rem(748);
      }
    }
  }
}

.telescope {
  &__back-btn {
    position: absolute;
    bottom: rem(8);
    right: 0;

    .is-desktop & {
      bottom: rem(13);
      right: rem(-12);
    }
  }
}
.telescope__responsive-container-item {
  position: absolute;
}

.telescope__responsive-container-item.tsboa-vn {
  width: rem(120);
  height: rem(130);
  top: rem(17);
  left: rem(2);

  .is-desktop & {
    width: rem(246);
    height: rem(60);
    left: 0;
    top: 25%;
  }

  .telescope {
    &__dot-box {
      left: rem(71);
      top: rem(86);

      .is-desktop & {
        top: 13%;
        left: unset;
        right: 0;
      }
    }
    &__description {
      top: 0;
      bottom: unset !important;

      .is-desktop & {
        left: -6%;
        top: 9%;
        flex-direction: row;
      }

      &::before,
      &::after {
        @extend %vector-lines !optional;
      }
      &::before {
        width: rem(1);
        height: rem(48);
        bottom: -153%;
        right: 49.5%;

        .is-desktop & {
          height: rem(116);
          rotate: 90deg;
          bottom: -100%;
          right: -33%;
        }
      }
      &::after {
        @extend %vector-dot !optional;
        bottom: -36%;
        right: rem(68);

        .is-desktop & {
          bottom: 40%;
          right: 8.8%;
        }
      }
    }
  }
}

.telescope__responsive-container-item.tsboa-us {
  width: rem(192);
  height: rem(64);
  top: rem(48);
  right: rem(-15);

  .is-desktop & {
    width: rem(300);
    height: rem(68);
    top: rem(104);
    right: rem(120);
  }
  .telescope {
    &__dot-box {
      top: unset;
      left: rem(7);
      bottom: rem(2);
      .is-desktop & {
        top: rem(1);
        left: rem(3);
      }
    }
    &__description {
      top: 0;
      right: 0;
      bottom: unset !important;
      left: unset !important;
      &::before,
      &::after {
        @extend %vector-lines !optional;
      }
      &::before {
        width: rem(1);
        height: rem(56);
        top: rem(6);
        left: rem(-6);
        rotate: 62deg;
        .is-desktop & {
          top: -133%;
          left: rem(-63);
          height: rem(150);
          rotate: 90deg;
        }
      }
      &::after {
        @extend %vector-dot !optional;
        top: rem(19);
        left: rem(18);
        .is-desktop & {
          left: rem(12);
          top: rem(20);
        }
      }
    }
  }
}

.telescope__responsive-container-item.uv1-so {
  width: rem(120);
  height: rem(173);
  bottom: rem(8);
  right: rem(102);

  .is-desktop & {
    width: rem(325);
    height: rem(224);
    right: 9%;
    bottom: rem(82);
  }
  .telescope {
    &__dot-box {
      top: rem(1);
      left: rem(48);
      .is-desktop & {
        top: -1.4%;
        left: 2.6%;
      }

      &::before {
        .is-desktop & {
          content: '';
          position: absolute;
          background-color: $TNO-action-orange;
          width: rem(1);
          height: rem(202);
          rotate: 159.2deg;
          top: 35%;
          right: -41%;
        }
      }
    }
    &__description {
      bottom: 0;
      left: -7%;

      .is-desktop & {
        left: unset;
        right: -3%;
      }

      &::before,
      &::after {
        @extend %vector-lines !optional;
      }
      &::before {
        width: rem(1);
        height: rem(104);
        bottom: 127%;
        right: rem(68);
        .is-desktop & {
          height: rem(98);
          bottom: rem(-30);
          left: rem(-46);
          rotate: 90deg;
        }
      }
      &::after {
        @extend %vector-dot !optional;
        bottom: 118%;
        right: rem(67);
        .is-desktop & {
          bottom: rem(17);
          left: rem(2);
        }
      }
    }
  }
}