@use "sass:math";
/*
  Styling for responsive image ratio's

  How to use:

  <figure class="img__responsive img img--cover aspect_16x9">
    <picture>
      <img src="#" />
    </picture>
  </figure>
*/

.img {
  overflow: hidden;
  margin: 0;
}
.img__responsive {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  figcaption{
    z-index: 10;
    position: absolute;
    bottom: 0;
    background: rgba($black, .5);
    color: $white;
    font-size: rem(12);
    padding: 1px rem(12);
    font-weight: 400;

    strong{
      font-weight: 700;
    }
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    margin: auto;
  }
  &.img--cover,
  &.img--contain,
  &.img--scale {
    height: 0;
    padding-bottom: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &.img--scale img {
    object-fit: scale-down;
    object-position: center;
  }
  &.img--contain img {
    object-fit: contain;
    object-position: center;
  }
  &.img--cover img {
    object-fit: cover;
    object-position: center;
  }
  &.aspect_1x1 {
    padding-bottom: 100%;
  }
  &.aspect_16x9 {
    padding-bottom: 56.5%;
  }
  &.aspect_11x6 {
    padding-bottom: math.div(6, 11) * 100%;
  }
  &.aspect_16x7 {
    padding-bottom: math.div(7.4, 16) * 100%;
  }
  &.aspect_16x8 {
    padding-bottom: math.div(8.5, 16) * 100%;
  }
  &.aspect_230x67 {
    padding-bottom: math.div(67, 230) * 100%;
  }
  &.aspect_27x25 {
    padding-bottom: math.div(27, 25) * 100%;
  }
  &.aspect_5x18 {
    padding-bottom: math.div(5, 18) * 100%;
  }
  &.aspect_135x362 {
    padding-bottom: math.div(135, 362) * 100%;
  }
  &.aspect_135x272 {
    padding-bottom: math.div(135, 272) * 100%;
  }
  &.aspect_119x256 {
    padding-bottom: math.div(119, 256) * 100%;
  }
  &.aspect_89x85 {
    padding-bottom: math.div(85, 89) * 100%;
  }
  &.aspect_31x64 {
    padding-bottom: math.div(31, 64) * 100%;
  }
  &.aspect_104x163 {
    padding-bottom: math.div(104, 163) * 100%;
  }
  &.aspect_236x369 {
    padding-bottom: math.div(369, 236) * 100%;
  }
  &.aspect_130x231 {
    padding-bottom: calc((130 / 231) * 100%);
  }
  &.aspect_103x183 {
    padding-bottom: calc((103 / 183) * 100%);
  }
}