$max-element-height: rem(351);
$max-element-height-desktop: rem(738);

.telescope {
  position: relative;
  background-color: $TNO-blue-dark;
  margin: rem(0) auto;
  height: rem(342);
  overflow-x: hidden;
  overflow-y: hidden;

  .is-desktop & {
    height: rem(670);
  }

  &__img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .is-desktop & {
      top: 47%;
    }

    figure {
      width: rem(350);

      .is-desktop & {
        width: rem(740);
      }
    }

  }

  figure.img__responsive {
    padding-bottom: 112%;
  }
  &__responsive-container.sentinel-5 {
    width: rem(300);
    height: 100%;
    margin: 0 auto;
    position: absolute;
    inset: 0;

    .is-desktop & {
      width: rem(830);
    }
  }

  &__dot-box {
    position: absolute;
    width: rem(30);
    height: rem(30);
    bottom: 72px;
    left: 114px;

    .is-desktop & {
      top: 69.3%;
      left: 42.5%;
      width: rem(40);
      height: rem(40);
    }
  }

  &__dot-box--wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $TNO-action-orange;
      border-radius: 100%;
      z-index: 1;
    }

    &::before {
      width: rem(24);
      height: rem(24);
      opacity: 0.25;

      .is-desktop & {
        width: rem(32);
        height: rem(32);
      }
    }
    &::after {
      border: 1px solid #000000;
      width: rem(10);
      height: rem(10);

      .is-desktop & {
        width: rem(15);
        height: rem(15);
      }
    }
  }

  &__dot-box--wrapper {
    -webkit-animation: pulsate 3s ease-out;
    -webkit-animation-iteration-count: infinite;
  }

  @-webkit-keyframes pulsate {
    40% {
      -webkit-transform: scale(0.5, 0.5);
    }
    50% {
      opacity: 1.0;
    }
    100% {
      -webkit-transform: scale(1, 1);
    }
  }

  &__description {
    position: absolute;
    z-index: 1;
    bottom: 4px;
    left: 16px;

    .is-desktop & {
      display: flex;
      flex-direction: row-reverse;
      bottom: rem(61);
      left: rem(104);

    }

    &--title {
      .is-desktop & {
        margin-top: rem(26);
        margin-left: rem(20);
      }
    }
    &--title h1 {
      color: $white;
      font-size: rem(16);
      font-weight: 700;
      line-height: rem(20);
      white-space: nowrap;
      text-align: center;
      display: flex;
      justify-content: center;
      width: rem(140);
      text-decoration: underline;
      text-decoration-color: $TNO-blue-dark;
      -webkit-text-decoration-color: $TNO-blue-dark;
      -moz-text-decoration-color: $TNO-blue-dark;
      transition: all 0.4s ease-in-out;

      .is-desktop & {
        font-size: rem(20);
        white-space: normal;
      }
    }
    &--vector {
      display: block;
      width: 100%;
      height: 100%;

    }
    &--vector--mobile {
      position: relative;
      left: 69px;
      top: -3px;
      max-width: rem(45);
      .is-desktop & {
        display: none;
        background: red;
        outline: red;
      }
    }
    &--vector--desktop {
      display: none;
      min-width: rem(112);
      position: relative;

      .is-desktop & {
        display: block;
        left: rem(-2);
      }

      img {
        object-position: top !important;
      }
    }
    &--vector--mobile::before,
    &--vector--desktop::before {
      content: '';
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      width: rem(4);
      height: rem(4);
      border-radius: 100%;
      background-color: $TNO-action-orange;
    }
    &--vector--mobile::before {
      bottom: 3px;
      left: 1px;
    }
    &--vector--desktop::before {
      top: rem(56);
    }
  }

  &__dot-box, &__description {
    &:hover {
      h1 {
        .is-desktop & {
          text-decoration: underline;
          text-decoration-color: $white;
        }
      }
    }
  }
}