.img {
  margin: 0;
  overflow: hidden;
}

.img__responsive {
  margin: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.img__responsive picture {
  position: absolute;
  inset: 0;
}

.img__responsive figcaption {
  z-index: 10;
  color: #fff;
  background: #00000080;
  padding: 1px .75rem;
  font-size: .75rem;
  font-weight: 400;
  position: absolute;
  bottom: 0;
}

.img__responsive figcaption strong {
  font-weight: 700;
}

.img__responsive img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  margin: auto;
  display: block;
}

.img__responsive.img--cover, .img__responsive.img--contain, .img__responsive.img--scale {
  height: 0;
  padding-bottom: 100%;
}

.img__responsive.img--cover img, .img__responsive.img--contain img, .img__responsive.img--scale img {
  width: 100%;
  height: 100%;
}

.img__responsive.img--scale img {
  object-fit: scale-down;
  object-position: center;
}

.img__responsive.img--contain img {
  object-fit: contain;
  object-position: center;
}

.img__responsive.img--cover img {
  object-fit: cover;
  object-position: center;
}

.img__responsive.aspect_1x1 {
  padding-bottom: 100%;
}

.img__responsive.aspect_16x9 {
  padding-bottom: 56.5%;
}

.img__responsive.aspect_11x6 {
  padding-bottom: 54.5455%;
}

.img__responsive.aspect_16x7 {
  padding-bottom: 46.25%;
}

.img__responsive.aspect_16x8 {
  padding-bottom: 53.125%;
}

.img__responsive.aspect_230x67 {
  padding-bottom: 29.1304%;
}

.img__responsive.aspect_27x25 {
  padding-bottom: 108%;
}

.img__responsive.aspect_5x18 {
  padding-bottom: 27.7778%;
}

.img__responsive.aspect_135x362 {
  padding-bottom: 37.2928%;
}

.img__responsive.aspect_135x272 {
  padding-bottom: 49.6324%;
}

.img__responsive.aspect_119x256 {
  padding-bottom: 46.4844%;
}

.img__responsive.aspect_89x85 {
  padding-bottom: 95.5056%;
}

.img__responsive.aspect_31x64 {
  padding-bottom: 48.4375%;
}

.img__responsive.aspect_104x163 {
  padding-bottom: 63.8037%;
}

.img__responsive.aspect_236x369 {
  padding-bottom: 156.356%;
}

.img__responsive.aspect_130x231 {
  padding-bottom: 56.2771%;
}

.img__responsive.aspect_103x183 {
  padding-bottom: 56.2842%;
}

.telescope__responsive-container-item.uv1-so .telescope__description:before, .telescope__responsive-container-item.uv1-so .telescope__description:after, .telescope__responsive-container-item.tsboa-us .telescope__description:before, .telescope__responsive-container-item.tsboa-us .telescope__description:after, .telescope__responsive-container-item.tsboa-vn .telescope__description:before, .telescope__responsive-container-item.tsboa-vn .telescope__description:after {
  content: "";
  background-color: #f57118;
  position: absolute;
}

.telescope__responsive-container-item.uv1-so .telescope__description:after, .telescope__responsive-container-item.tsboa-us .telescope__description:after, .telescope__responsive-container-item.tsboa-vn .telescope__description:after {
  width: .25rem;
  height: .25rem;
  border-radius: 100%;
}

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-xs, .col-xs-auto, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1 {
  width: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
  position: relative;
}

@media (min-width: 768px) {
  .col-xs {
    min-width: 0;
    flex: 1 0;
  }

  .col-xs-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xs-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xs-3 {
    width: 25%;
    flex: none;
  }

  .col-xs-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xs-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xs-6 {
    width: 50%;
    flex: none;
  }

  .col-xs-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xs-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xs-9 {
    width: 75%;
    flex: none;
  }

  .col-xs-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xs-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xs-12 {
    width: 100%;
    flex: none;
  }

  .offset-xs-0 {
    margin-left: 0;
  }

  .offset-xs-1 {
    margin-left: 8.33333%;
  }

  .offset-xs-2 {
    margin-left: 16.6667%;
  }

  .offset-xs-3 {
    margin-left: 25%;
  }

  .offset-xs-4 {
    margin-left: 33.3333%;
  }

  .offset-xs-5 {
    margin-left: 41.6667%;
  }

  .offset-xs-6 {
    margin-left: 50%;
  }

  .offset-xs-7 {
    margin-left: 58.3333%;
  }

  .offset-xs-8 {
    margin-left: 66.6667%;
  }

  .offset-xs-9 {
    margin-left: 75%;
  }

  .offset-xs-10 {
    margin-left: 83.3333%;
  }

  .offset-xs-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1024px) {
  .col-sm {
    min-width: 0;
    flex: 1 0;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1280px) {
  .col-md {
    min-width: 0;
    flex: 1 0;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1366px) {
  .col-lg {
    min-width: 0;
    flex: 1 0;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1920px) {
  .col-xl {
    min-width: 0;
    flex: 1 0;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.container {
  width: 100%;
  max-width: 70.625rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 1280px) {
  .container {
    max-width: 85vw;
  }
}

@media (min-width: 1366px) {
  .container {
    max-width: 70.625rem;
    padding-left: 0;
    padding-right: 0;
  }
}

.container.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.container.no-padding > .row {
  margin-left: 0;
  margin-right: 0;
}

.container.no-padding > .row > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.container--no-max {
  max-width: unset;
}

.container.no-padding-mobile {
  padding-left: 0;
  padding-right: 0;
}

.container.no-padding-mobile > .row {
  margin-left: 0;
  margin-right: 0;
}

.container.no-padding-mobile > .row > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 1280px) {
  .container.no-padding-mobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .container.no-padding-mobile > .row {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .container.no-padding-mobile > .row > [class*="col-"] {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -.5rem;
  margin-right: -.5rem;
  display: flex;
}

.row--no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.row--no-gutter > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
  text-decoration: none;
}

:focus-visible {
  outline: 3px solid #123eb7 !important;
}

html {
  scroll-behavior: smooth;
  height: 21.375rem;
  background: #fff;
}

html.is-desktop {
  height: 41.875rem;
  overflow: visible;
}

body {
  color: #fff;
  height: 21.375rem;
  margin: 0 auto;
  font-family: Object Sans, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.is-desktop body {
  height: 41.875rem;
}

body .intro {
  width: 70vw;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  body .intro {
    width: 60vw;
  }
}

@media (min-width: 1280px) {
  body .intro {
    width: 50vw;
  }
}

@media (min-width: 1366px) {
  body .intro {
    width: 40vw;
  }
}

@media (min-width: 1920px) {
  body .intro {
    width: 30vw;
  }
}

body .intro h1 {
  color: #000;
  padding-top: 2.5rem;
}

body .intro p {
  padding-top: 1rem;
}

ol {
  margin-left: 0;
}

ul {
  list-style: none;
}

ul li {
  padding-left: 0;
  position: relative;
}

table {
  border-collapse: collapse;
}

small {
  font-size: 80%;
}

button {
  appearance: none;
  cursor: pointer;
  background: none;
}

p {
  color: #00030a;
  font-weight: 400;
}

details summary::-webkit-details-marker {
  visibility: hidden;
}

span {
  font-weight: 500;
}

.telescope {
  height: 21.375rem;
  background-color: #002484;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.is-desktop .telescope {
  height: 41.875rem;
}

.telescope__img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.is-desktop .telescope__img {
  top: 47%;
}

.telescope__img figure {
  width: 21.875rem;
}

.is-desktop .telescope__img figure {
  width: 46.25rem;
}

.telescope figure.img__responsive {
  padding-bottom: 112%;
}

.telescope__responsive-container.sentinel-5 {
  width: 18.75rem;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  inset: 0;
}

.is-desktop .telescope__responsive-container.sentinel-5 {
  width: 51.875rem;
}

.telescope__dot-box {
  width: 1.875rem;
  height: 1.875rem;
  position: absolute;
  bottom: 72px;
  left: 114px;
}

.is-desktop .telescope__dot-box {
  width: 2.5rem;
  height: 2.5rem;
  top: 69.3%;
  left: 42.5%;
}

.telescope__dot-box--wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.telescope__dot-box--wrapper:before, .telescope__dot-box--wrapper:after {
  content: "";
  z-index: 1;
  background: #f57118;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.telescope__dot-box--wrapper:before {
  width: 1.5rem;
  height: 1.5rem;
  opacity: .25;
}

.is-desktop .telescope__dot-box--wrapper:before {
  width: 2rem;
  height: 2rem;
}

.telescope__dot-box--wrapper:after {
  width: .625rem;
  height: .625rem;
  border: 1px solid #000;
}

.is-desktop .telescope__dot-box--wrapper:after {
  width: .9375rem;
  height: .9375rem;
}

.telescope__dot-box--wrapper {
  -webkit-animation: 3s ease-out infinite pulsate;
}

@-webkit-keyframes pulsate {
  40% {
    -webkit-transform: scale(.5);
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
  }
}

.telescope__description {
  z-index: 1;
  position: absolute;
  bottom: 4px;
  left: 16px;
}

.is-desktop .telescope__description {
  flex-direction: row-reverse;
  display: flex;
  bottom: 3.8125rem;
  left: 6.5rem;
}

.is-desktop .telescope__description--title {
  margin-top: 1.625rem;
  margin-left: 1.25rem;
}

.telescope__description--title h1 {
  color: #fff;
  white-space: nowrap;
  text-align: center;
  width: 8.75rem;
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  text-decoration: underline #002484;
  transition: all .4s ease-in-out;
  display: flex;
}

.is-desktop .telescope__description--title h1 {
  white-space: normal;
  font-size: 1.25rem;
}

.telescope__description--vector {
  width: 100%;
  height: 100%;
  display: block;
}

.telescope__description--vector--mobile {
  max-width: 2.8125rem;
  position: relative;
  top: -3px;
  left: 69px;
}

.is-desktop .telescope__description--vector--mobile {
  background: red;
  outline: red;
  display: none;
}

.telescope__description--vector--desktop {
  min-width: 7rem;
  display: none;
  position: relative;
}

.is-desktop .telescope__description--vector--desktop {
  display: block;
  left: -.125rem;
}

.telescope__description--vector--desktop img {
  object-position: top !important;
}

.telescope__description--vector--mobile:before, .telescope__description--vector--desktop:before {
  content: "";
  width: .25rem;
  height: .25rem;
  background-color: #f57118;
  border-radius: 100%;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
}

.telescope__description--vector--mobile:before {
  bottom: 3px;
  left: 1px;
}

.telescope__description--vector--desktop:before {
  top: 3.5rem;
}

.is-desktop .telescope__dot-box:hover h1, .is-desktop .telescope__description:hover h1 {
  text-decoration: underline #fff;
}

.hidden {
  display: none;
}

.hidden-visibility {
  opacity: 0;
  pointer-events: none;
}

.icon {
  height: 2.4375rem;
  width: 2.375rem;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.is-desktop .icon {
  height: 3.0625rem;
  width: 3rem;
}

.icon--back-btn {
  background-image: url("icon-back-btn.79a7f02d.svg");
  margin-right: .5625rem;
}

.icon--back-btn-popup {
  background-image: url("icon-back-btn-popup.89d1acd3.svg");
}

.telescope-inside .img__responsive.img--contain img {
  object-position: center;
}

.telescope-inside .telescope__responsive-container {
  width: 21.4375rem;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  inset: 0;
}

.is-desktop .telescope-inside .telescope__responsive-container {
  width: 51.875rem;
}

.telescope-inside .telescope__description--title {
  margin: 0;
}

.is-desktop .telescope-inside .telescope__description--title h1 {
  font-size: 1rem;
}

.is-desktop .telescope-inside .telescope__img {
  top: 53.7%;
}

.telescope-inside .telescope__img figure {
  width: 20.1875rem;
}

.is-desktop .telescope-inside .telescope__img figure {
  width: 46.75rem;
}

.telescope__back-btn {
  position: absolute;
  bottom: .5rem;
  right: 0;
}

.is-desktop .telescope__back-btn {
  bottom: .8125rem;
  right: -.75rem;
}

.telescope__responsive-container-item {
  position: absolute;
}

.telescope__responsive-container-item.tsboa-vn {
  width: 7.5rem;
  height: 8.125rem;
  top: 1.0625rem;
  left: .125rem;
}

.is-desktop .telescope__responsive-container-item.tsboa-vn {
  width: 15.375rem;
  height: 3.75rem;
  top: 25%;
  left: 0;
}

.telescope__responsive-container-item.tsboa-vn .telescope__dot-box {
  top: 5.375rem;
  left: 4.4375rem;
}

.is-desktop .telescope__responsive-container-item.tsboa-vn .telescope__dot-box {
  top: 13%;
  left: unset;
  right: 0;
}

.telescope__responsive-container-item.tsboa-vn .telescope__description {
  top: 0;
  bottom: unset !important;
}

.is-desktop .telescope__responsive-container-item.tsboa-vn .telescope__description {
  flex-direction: row;
  top: 9%;
  left: -6%;
}

.telescope__responsive-container-item.tsboa-vn .telescope__description:before {
  width: .0625rem;
  height: 3rem;
  bottom: -153%;
  right: 49.5%;
}

.is-desktop .telescope__responsive-container-item.tsboa-vn .telescope__description:before {
  height: 7.25rem;
  bottom: -100%;
  right: -33%;
  rotate: 90deg;
}

.telescope__responsive-container-item.tsboa-vn .telescope__description:after {
  bottom: -36%;
  right: 4.25rem;
}

.is-desktop .telescope__responsive-container-item.tsboa-vn .telescope__description:after {
  bottom: 40%;
  right: 8.8%;
}

.telescope__responsive-container-item.tsboa-us {
  width: 12rem;
  height: 4rem;
  top: 3rem;
  right: -.9375rem;
}

.is-desktop .telescope__responsive-container-item.tsboa-us {
  width: 18.75rem;
  height: 4.25rem;
  top: 6.5rem;
  right: 7.5rem;
}

.telescope__responsive-container-item.tsboa-us .telescope__dot-box {
  top: unset;
  bottom: .125rem;
  left: .4375rem;
}

.is-desktop .telescope__responsive-container-item.tsboa-us .telescope__dot-box {
  top: .0625rem;
  left: .1875rem;
}

.telescope__responsive-container-item.tsboa-us .telescope__description {
  top: 0;
  right: 0;
  bottom: unset !important;
  left: unset !important;
}

.telescope__responsive-container-item.tsboa-us .telescope__description:before {
  width: .0625rem;
  height: 3.5rem;
  top: .375rem;
  left: -.375rem;
  rotate: 62deg;
}

.is-desktop .telescope__responsive-container-item.tsboa-us .telescope__description:before {
  height: 9.375rem;
  top: -133%;
  left: -3.9375rem;
  rotate: 90deg;
}

.telescope__responsive-container-item.tsboa-us .telescope__description:after {
  top: 1.1875rem;
  left: 1.125rem;
}

.is-desktop .telescope__responsive-container-item.tsboa-us .telescope__description:after {
  top: 1.25rem;
  left: .75rem;
}

.telescope__responsive-container-item.uv1-so {
  width: 7.5rem;
  height: 10.8125rem;
  bottom: .5rem;
  right: 6.375rem;
}

.is-desktop .telescope__responsive-container-item.uv1-so {
  width: 20.3125rem;
  height: 14rem;
  bottom: 5.125rem;
  right: 9%;
}

.telescope__responsive-container-item.uv1-so .telescope__dot-box {
  top: .0625rem;
  left: 3rem;
}

.is-desktop .telescope__responsive-container-item.uv1-so .telescope__dot-box {
  top: -1.4%;
  left: 2.6%;
}

.is-desktop .telescope__responsive-container-item.uv1-so .telescope__dot-box:before {
  content: "";
  width: .0625rem;
  height: 12.625rem;
  background-color: #f57118;
  position: absolute;
  top: 35%;
  right: -41%;
  rotate: 159.2deg;
}

.telescope__responsive-container-item.uv1-so .telescope__description {
  bottom: 0;
  left: -7%;
}

.is-desktop .telescope__responsive-container-item.uv1-so .telescope__description {
  left: unset;
  right: -3%;
}

.telescope__responsive-container-item.uv1-so .telescope__description:before {
  width: .0625rem;
  height: 6.5rem;
  bottom: 127%;
  right: 4.25rem;
}

.is-desktop .telescope__responsive-container-item.uv1-so .telescope__description:before {
  height: 6.125rem;
  bottom: -1.875rem;
  left: -2.875rem;
  rotate: 90deg;
}

.telescope__responsive-container-item.uv1-so .telescope__description:after {
  bottom: 118%;
  right: 4.1875rem;
}

.is-desktop .telescope__responsive-container-item.uv1-so .telescope__description:after {
  bottom: 1.0625rem;
  left: .125rem;
}

.popup {
  visibility: hidden;
  width: calc(100% - 48px);
  height: -moz-fit-content;
  height: fit-content;
  z-index: 2;
  background: #e7ecf8;
  padding: .875rem 2.75rem .875rem 1.125rem;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: inset 0 -.25rem .5rem #0000000d;
}

.is-desktop .popup {
  width: 23.3125rem;
  top: 2.5rem;
  left: unset;
  transform: unset;
  padding: 1.5rem 1.5rem 4.5rem;
  right: 2.5rem;
  box-shadow: 0 .25rem .25rem #00000040;
}

.popup h1 {
  color: #123eb7;
  margin-bottom: .5rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.is-desktop .popup h1 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

.popup p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.is-desktop .popup p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.popup__back-btn {
  position: absolute;
  bottom: 4%;
  right: .5625rem;
}

.is-desktop .popup__back-btn {
  bottom: 1.5rem;
  right: 1.5rem;
}

.popup.show {
  visibility: visible;
  left: 50%;
}

/*# sourceMappingURL=index.c782b174.css.map */
