.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc($grid-gutter-width / -2);
  margin-right: calc($grid-gutter-width / -2);
  &--no-gutter {
    margin-left: 0;
    margin-right: 0;
    >[class*='col-']{
      padding-left: 0;
      padding-right: 0;
    }
  }
}